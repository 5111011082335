// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-client-form {
    margin-top: 20px;
  }
  
  .add-client-form h3 {
    margin-bottom: 10px;
  }
  
  .add-client-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* .add-client-form input {
    /* width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px; 
  } */
  
  .add-client-form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .add-client-form button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AddSubscriptionForm/addSubscriptionForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;;;;;;KAMG;;EAEH;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,eAAe;EACjB","sourcesContent":[".add-client-form {\n    margin-top: 20px;\n  }\n  \n  .add-client-form h3 {\n    margin-bottom: 10px;\n  }\n  \n  .add-client-form label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  /* .add-client-form input {\n    /* width: 100%;\n    padding: 8px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px; \n  } */\n  \n  .add-client-form button {\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n  }\n  \n  .add-client-form button:hover {\n    background-color: #0056b3;\n  }\n  \n  .error-message {\n    color: red;\n    margin-top: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
