// AddCourseForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function AddEssayForm({ onAddEssay, selectedEssay, cancelBtnHandler }) {
  const[essayObj, setEssayObj] = useState({
    EssayTittle:"",
    EssayDescription:""
  })

  const [clients, setClients] = useState([]);
  const [formType,setFormType] = useState('Add');

  useEffect(() => {
    if (selectedEssay) {
      setEssayObj({
        ...selectedEssay,
        EssayTittle : selectedEssay.essay_name,
        EssayDescription : selectedEssay.essay_desc,
        selectedClient: selectedEssay?.subscription_id?._id,
      });
      setFormType('Update');
      if(selectedEssay?.subscription_id?._id) fetchClients();
    }
  }, [selectedEssay]);

  useEffect(() => {
    fetchClients();
  }, []);

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    setEssayObj((prevState) => {
      return {
        ...prevState,
        selectedClient: clientId,
        selectedEssay: "",
      };
    });
    fetchClients(clientId);
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get('http://localhost:8891/api/subscriptions').then(data => {
        if (data.data.clientsums)
        {
          let clinets = Object.keys(data.data.clientsums).map(d => {return { _id : d , firm_name : data.data.clientsums[d] }});
          setClients(clinets|| []);
        }
      })
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8891/api/essays', { 
        essay_name: essayObj.EssayTittle, 
        essay_desc : essayObj.EssayDescription,
        subscription_id: essayObj.selectedClient });
      console.log('New Essay added:', response.data);
      // setCourseObj({
      //   courseName:"",
      //   selectedClient:""
      // })
      onAddEssay();
      //setCourseName('');
    } catch (error) {
      console.error('Error adding Essay', error);
    }
  };

  const EdithandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`http://localhost:8891/api/essays/${essayObj._id}`, { 
        essay_name:essayObj.EssayTittle, 
        essay_desc : essayObj.EssayDescription,
        subscription_id: essayObj.selectedClient });

      console.log('Essay Edited', response.data);
          onAddEssay();
      //setCourseName('');
    } catch (error) {
      console.error('Error Editing Essay:', error);
    }
  };

  return (
    <div className="add-essay-form general-add-form-container">
      <h3>Add Essay</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Client: </label>
          <select
              className="form-select"
              value={essayObj.selectedClient}
              onChange={handleClientChange }
              required 
            >
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client._id} value={client._id}>
                  {client.firm_name}
                </option>
              ))}
            </select>
        </div>
        <div>
          <label> Tittle:</label>
          <input type="text" 
          value={essayObj.EssayTittle}
          onChange={(e) =>
            setEssayObj((prevState) => {
              return { ...prevState, EssayTittle: e.target.value };
            })
          }required />
        </div>
        <div>
          <label> Description:</label>
          <input type="text" 
          value={essayObj.EssayDescription}
          onChange={(e) =>
            setEssayObj((prevState) => {
              return { ...prevState, EssayDescription: e.target.value };
            })
          }required />
        </div>
        {/* <button type="submit">Add Course</button> */}

        <div>
        {
              formType === 'Add' ? <button type="submit" className="add-form-btn">
                          <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
              </svg>
            </span>
            {formType} 
             Now
          </button>:
            <button onClick={EdithandleSubmit} className="add-form-btn">
            {formType}  Now
          </button>
           }
          <button type="submit" class="cancel-form-btn" onClick={cancelBtnHandler}>
            <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>
            </span>
            Cancel
          </button>

        </div>
      </form>
    </div>
  );
}
