// Courses.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './essays.css'; // Import CSS file
import AddEssayForm from '../AddEssayForm/addEssayForm'; // Import the AddEssayForm component

export default function Essays() {
  const [essays, setEssays] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false); // State variable to manage form visibility
  const [selectedEssay, setSelectedEssay]=useState();
  const [clientsums, setClientsUms] = useState([]);
  const [clients, setClients] = useState([]);
  useEffect(() => {
    fetchEssays();
    // fetchClients();
  }, [page]); // Fetch courses whenever page changes

  const fetchEssays = async () => {
    try {
      const response = await axios.get(`http://localhost:8891/api/essays?page=${page}`);
      const { essays, totalPages, clientsums} = response.data;
      setEssays(essays);
      setClientsUms(clientsums || []);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching Essay:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(`http://localhost:8891/api/subscriptions?page=${page}`);
      const { clients, totalPages, clientsums } = response.data;
      setClients(clients || []);
      setClientsUms(clientsums || []);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };



  const handleEssayAdded = () => {
    // Close the form and refresh the course list
    setShowForm(false);
    fetchEssays();
  };

  const addBtnHandler = () => {
    setShowForm(true);
    setSelectedEssay();
 };

  const editBtnHandler = (essay) => {
    setShowForm(true);
    setSelectedEssay(essay);
};

const cancelBtnHandler = () => {
  setShowForm(false);
};

const deleteHandler = async (essay_id) => {
  try {
    await axios.delete(
      `http://localhost:8891/api/essays/${essay_id}`
    ).then(d => {
        fetchEssays();
    });
  } catch (error) {
    console.error("Error Essay Deleted:", error);
  }
};

return (
  <div className="essays-container">
    {/* <h2>Topics</h2>
    <button onClick={() => setShowForm(!showForm)}>Add Topic</button>
    {showForm && <AddTopicForm onAddTopic={handleTopicAdded} />} */}

    {/* =============================================== */}
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8">
          <div class="page-header-container">
            <h2 class="page-main-header">Essays</h2>
            <button onClick={addBtnHandler} class="add-form-btn">
              <span class="icon-placeholder">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                  <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
                </svg>
              </span>
              Add Essay
            </button>
          </div>
        </div>
        <div class="col-sm-4"> &nbsp; </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p class="mb-0">&nbsp;</p>
          {showForm && <AddEssayForm onAddEssay={handleEssayAdded} cancelBtnHandler={cancelBtnHandler} selectedEssay={selectedEssay} />}
        </div>
      </div>
    </div>
    {/* ================================================ */}

    {/* <ul className="topic-list">
      {topics.map((topic, index) => (
        <li key={index} className="topic-item">
          <span className="topic-name">{topic.topic_name}</span>
          <span className="assigned-subject">Assigned Subject: {topic.subject_id ? topic.subject_id.subject_name : 'N/A'}</span>
          <span className="created-date">Created: {new Date(topic.created.date).toLocaleDateString()}</span>
        </li>
      ))}
    </ul> */}

    {/* ================================================ */}
    <ul className="essay-list row row-cols-1 row-cols-md-4 g-4">
        {essays.map((essay, index) => (
          <li key={index} className="page-added-item col">
            <div class="added-items-list card h-100">

              <div class="card-body">
              <strong>Tittle:</strong><br />
                <h5 class="card-title min-ht-25">{essay.essay_name}</h5>
                <strong>Description:</strong><br />
                <p>{essay.essay_desc}</p>
                <p class="card-text">
                  <strong>Client:</strong><br />
                  {clients.find(client => client._id == essay.client_id)?.firm_name}
                </p>
              </div>
            <div class="card-footer">

            <button className="delete_item" id="editQuestion" onClick={() => editBtnHandler(essay)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                </svg> &nbsp; Edit  
              </button>
            
              <button className="delete_item" id="editQuestion" onClick={() => { if(window.confirm('Are you sure to delete this Topic?')) deleteHandler(essay._id)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
              </svg> Delete </button>
              <p class="text-body-secondary mb-0">
                <strong>Created:</strong> {new Date(essay.created.date).toLocaleDateString()}
              </p>
              </div>
          </div>
        </li>
      ))}
    </ul>
      {/* ================================================ */}

      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
