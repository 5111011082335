import React from 'react';
import './default.css'; // Import CSS file

export default function HomeContent() {
    return (
      // <div>
      //   <h2>Welcome to the Home Page</h2>
      //   <p>This is the home page content.</p>
      // </div>

      <div class="default-screen-wrapper">
        <div class="typing-demo">
        Welcome to the Home Page..
        </div>
      </div>
    );
  }