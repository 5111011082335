import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './subscriptions.css';
import AddClientForm from '../AddSubscriptionForm/addSubscriptionForm';

export default function Clients() {
  const [clients, setClients] = useState([]);
  const [clientsums, setClientsUms] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false); // State variable to manage form visibility
  const [selectedClient, setSelectedClient] = useState();
  const [existingsElectedClient,setExistingSelectedClient] =useState([]);

  const APP_API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchClients();
  }, [page]); // Fetch clients whenever page changes

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${APP_API_URL}/subscriptions?page=${page}`);
      const { clients, totalPages, clientsums } = response.data;
      // Set isActive to true for each client
      const clientsWithDefaultActive = clients.map(client => ({ ...client, isActive: true }));
      setClients(clientsWithDefaultActive || []);
      setClientsUms(clientsums || []);
      setTotalPages(totalPages);
      const selectedClientIds = clients.map(client => client.client_id);
      setExistingSelectedClient(selectedClientIds);


    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };




  const handleClientAdded = () => {
    // Close the form and refresh the client list
    setShowForm(false);
    fetchClients();
    
  };

  const addBtnHandler = () => {
    setShowForm(true);
    setSelectedClient();
    
  };

  const cancelBtnHandler = () => {
    setShowForm(false);
  };

  const handleDeleteClient = async (_id) => {
    try {
      await axios.delete(`${APP_API_URL}/subscriptions/${_id}`);
      fetchClients();
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleToggleSubscription = async (client) => {
    try {
      var c = client;
      
      c['status'] = (c['status'] == 0) ? 1 : 0;

      await axios.patch(`${APP_API_URL}/subscriptions/${client._id}`, c);
      fetchClients();
    } catch (error) {
      console.error('Error updating client status:', error);
    }
  };
  return (
    <div className="clients-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-8">
            <div className="page-header-container">
              <h2 className="page-main-header">Subscription</h2>
              <button onClick={addBtnHandler} className="add-form-btn">
                <span className="icon-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
                  </svg>
                </span>
                Add Subscription
              </button>
            </div>
          </div>
          <div className="col-sm-4"> &nbsp; </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p className="mb-0">&nbsp;</p>
            {showForm && <AddClientForm onAddClient={handleClientAdded} 
            cancelBtnHandler={cancelBtnHandler} selectedClients={selectedClient}
            existingSelectedClients={existingsElectedClient}/>}
          </div>
        </div>
      </div>

      <ul className="client-list row row-cols-1 row-cols-md-3 g-4">
        {clients.map((client, index) => (
          <li key={index} className="client-item col">
            <div className="clients-list card h-100">
              <div className="card-body">
                <h5 className="card-title">
                  <strong>Client Name:</strong><br />
                  {clientsums[client.client_id]}
                </h5>
                <p className="card-text">
                  <strong>Subscription ID:</strong><br />
                  {client.subscription_id}
                </p>
              </div>
              <div className="card-footer">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={client.status}
                    onChange={() => handleToggleSubscription(client)}
                  />
                  <span className="slider"></span>
                </label>
                <button className="delete_item" onClick={() => { if (window.confirm('Are you sure to delete this Client?')) handleDeleteClient(client._id) }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg> Delete
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
