// AddLabelForm.js
import React, { useState } from 'react';
import axios from 'axios';

export default function AddLabelForm({ onAddLabel }) {
  const [labelName, setLabelName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8891/api/labels', { label: labelName });
      console.log('New label added:', response.data);
      onAddLabel();
      setLabelName('');
    } catch (error) {
      console.error('Error adding label:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Label name"
        value={labelName}
        onChange={(e) => setLabelName(e.target.value)}
        required
      />
      <button type="submit">Add Label</button>
    </form>
  );
}
