import React from 'react';
import './header.css'; // Import CSS file
import Context from '../../user_details';
import { useContext } from 'react';
import Cookies from 'js-cookie';
import userMenuIcon from '../../assets/profile.svg';

export default function Header() {
  const {user} = useContext(Context);
 console.log("user in hreader",user )
  const logout = ()=>{
    Cookies.remove("nexus_qBank_login");
  }
  return (
    <header className="header">
      <div className="logo">
        <img src="logos/logo-white.png" alt="Logo" />
      </div>
      <div className="user-info dropdown d-flex justify-content-end">
        <div className="p-1 d-inline-flex justify-content-center align-items-center gap-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <p className='m-0'>{`${user.first_name} ${user.last_name}`}</p>
          <img src={userMenuIcon} height={40}/>
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/" onClick={logout}>Logout</a>
        </div>
      </div>
      
    </header>
  );
}
