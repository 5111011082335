// soundHelper.js
import successSound from '../assets/sounds/success.mp3';
import errorSound from '../assets/sounds/error.mp3';

export const playSound = (soundType) => {

    let sound = successSound
    if(soundType == 'ERROR') {
        sound = errorSound
    }

    const audio = new Audio(sound);
    audio.play();
};
