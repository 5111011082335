import React from 'react';
import './footer.css'; // Import CSS file

export default function Footer() {
  return (
    <footer>
      <h4 class="footer-content">Copyright © 2024 NexusIQ Solutions. All rights reserved.</h4>
    </footer>
  );
}
