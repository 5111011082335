import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4 from uuid package
import './addSubscriptionForm.css'; // Import CSS file

export default function AddClientForm({ onAddClient, selectedClients, cancelBtnHandler,existingSelectedClients }) {
  
  const [clientObj, setClientObj] = useState({
    clientName:"",
    subscriptionId:""
  });
  //const [clientName, setClientName] = useState('');
  //const [subscriptionId, setSubscriptionId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formType,setFormType] = useState('Add');
  const [clients, setClients] = useState([]);

  

  useEffect(() => {
    if (selectedClients) {
      setClientObj({
        ...selectedClients,
        clientName : selectedClients.client_id,
        subscriptionId: selectedClients?.subscription_id?._id,
    
      });
      setFormType('Update');
      if (selectedClients?.subscription_id?._id);
      //  fetchClients(selectedClients?.subscriptionId?._id);
    }
  }, [selectedClients]);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('http://localhost:8891/api/subscriptions').then(data => {
        if (data.data.clientsums)
        {
          let clinets = Object.keys(data.data.clientsums).map(d => {return { _id : d , firm_name : data.data.clientsums[d] }});
          setClients(clinets|| []);
        }
      })
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const subscriptionId = clientObj.subscriptionId || generateSubscriptionId();
      const response = await axios.post('http://localhost:8891/api/subscriptions', { 
        client_id: clientObj.clientName,
        subscription_id: subscriptionId  // Use existing subscription ID or generate a new one
      });
      console.log('New client added:', response.data);
      // Clear form fields after successful submission
      //setClientName('');
      //setSubscriptionId('');
      setErrorMessage('');
      // Notify the parent component that a new client has been added
      onAddClient();
    } catch (error) {
      console.error('Error adding client:', error);
      setErrorMessage('Failed to add client. Please try again.');
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const subscriptionId = clientObj.subscriptionId || generateSubscriptionId();
      const response = await axios.patch(`http://localhost:8891/api/subscriptions/${clientObj._id}`, { 
        client_id: clientObj.clientName,
        subscription_id: subscriptionId // Use existing subscription ID or generate a new one
      });
      console.log('Client Edited:', response.data);
      // Clear form fields after successful submission
      //setClientName('');
      //setSubscriptionId('');
      setErrorMessage('');
      // Notify the parent component that a new client has been added
      onAddClient();
    } catch (error) {
      console.error('Error Editing client:', error);
      setErrorMessage('Failed to Edit client. Please try again.');
    }
  };

  // const handleClientNameChange = (event) => {
  //   const newName = event.target.value;
  //   const sanitizedName = newName.replace(/[^a-zA-Z0-9-]/g, '-'); // Replace spaces or non-alphanumeric characters with hyphens
  //   setClientObj(newName);
  //   // Append sanitized client name to subscription ID
  //   setClientObj(subscriptionId => `${uuidv4()}-${sanitizedName}`);
  // }; 
  
  const handleClientSelection = (event) => {
    const newName = event.target.value;
    const sanitizedClientName = newName.replace(/[^a-zA-Z0-9-]/g, '-');
    const newSubscriptionId = `${uuidv4()}-${sanitizedClientName}`;
    setClientObj(prevState => ({
      ...prevState,
      clientName: newName,
      subscriptionId: newSubscriptionId
    }));
  };
  // Function to generate a unique subscription ID
  const generateSubscriptionId = () => {
    return uuidv4();
  };

  const availableClients = clients.filter(client => 
    Array.isArray(existingSelectedClients) && !existingSelectedClients.includes(client._id)
  );
  

  return (
    <div className="add-client-form general-add-form-container">
      <h3>Add Subscription</h3>
      <form onSubmit={formType === 'Add' ? handleSubmit : handleEditSubmit}>
      {/* <form onSubmit={handleSubmit}> */}
        <div>
          <label>Select Client:</label>
           <select
            className="form-select"
            value={clientObj.clientName}
            onChange={handleClientSelection}
            required
          >
            <option value="">Select Client</option>
            {availableClients.map(client => (
              <option key={client._id} value={client._id}>
                {client.firm_name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Subscription ID:</label>
          <input
            type="text"
            value={clientObj.subscriptionId}
            onChange={(e) => setClientObj(e.target.value)}
            readOnly
            required
          />
        </div>
        <div>
        {
              formType === 'Add' ? <button type="submit" className="add-form-btn">
                          <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
              </svg>
            </span>
            {formType} 
             Now
          </button>:
            <button onClick={handleEditSubmit} className="add-form-btn">
            {formType}  Now
          </button>
           }
          <button type="submit" class="cancel-form-btn" onClick={cancelBtnHandler}>
            <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>
            </span>
            Cancel
          </button>
        </div>
        
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
