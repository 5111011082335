import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { playSound } from '../../helpers/otherSounds';

export default function AddSubjectForm({ onAddSubject, selectedSubject, cancelBtnHandler }) {
  const[subjectObj, setSubjectObj] = useState({
    subjectName:"",
    selectedCourses:""
  })
  //   const [subjectName, setSubjectName] = useState(''); 
  // const [selectedCourses, setSelectedCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [formType,setFormType] = useState('Add');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

useEffect(()=>{
  if(selectedSubject) {
      setSubjectObj({
        ...selectedSubject,
        subjectName:selectedSubject.subject_name,
        selectedCourses:selectedSubject?.course_id?._id,
    });

    setFormType('update');
    if(selectedSubject?.course_id?._id) fetchCourses(selectedSubject?.course_id?._id);
  }
}, [selectedSubject]);



  useEffect(() => {
    fetchCourses();
  }, []);
 
  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses`);
      if (response.data && response.data.courses) {
        setCourses(response.data.courses);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleAddSubject = async (newSubject) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects`, newSubject );

      console.log('New subject added:', response.data);
      setMessage('Subject added successfully!');
      setMessageType('success');
      playSound('SUCCESS');
      setTimeout(()=>{onAddSubject()}, 1000);
      //setCourseName('');
    } catch (error) {
      console.error('Error adding subject:', error);
      setMessage('Error adding subject.');
      setMessageType('error');
      playSound('ERROR');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSubject = { 
      subject_name: subjectObj.subjectName, 
      course_ids: subjectObj.selectedCourses // Change to selectedCourses array
    };
    handleAddSubject(newSubject);
    // Reset form fields after submission
    //setSubjectName('');
    //setSelectedCourses([]);
  };

  const EdithandleSubject = async (updateSubject) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/subjects/${subjectObj._id}`, updateSubject );

      console.log('Subject Updated Succesfully added:', response.data);
      setMessage('Subject Updated successfully!');
      setMessageType('success');
      playSound('SUCCESS');
      setTimeout(()=>{onAddSubject()}, 1000);
      //setCourseName('');
    } catch (error) {
      console.error('Error updating subject:', error);
      setMessage('Error updating subject.');
      setMessageType('error');
      playSound('ERROR');
    }
  };

  const EdithandleSubmit = (e) => {
    e.preventDefault();
    const updateSubject = { 
      subject_name: subjectObj.subjectName, 
      course_ids: subjectObj.selectedCourses // Change to selectedCourses array
    };
    EdithandleSubject(updateSubject);
    // Reset form fields after submission
    //setSubjectName('');
    //setSelectedCourses([]);
  };


  const handleSubjectNameChange = (e) => {
    setSubjectObj({ ...subjectObj, subjectName: e.target.value });
  };

  const handleCourseChange = (e) => {
    const selectedCourses = Array.from(e.target.selectedOptions, (option) => option.value);
    setSubjectObj({ ...subjectObj, selectedCourses });
  };

  return (
    <div className="add-subject-form general-add-form-container">
      <h3> {formType} Subject</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group vertical-middle mb-0">
          <label>Select Course:</label>
          <select class="multi-seletion-control"
            multiple // Enable multiple selection
            value={subjectObj.selectedCourses} 
            onChange={handleCourseChange} 
            required
          >
            {/* Provide key as course._id and value as course._id */}
            {courses.map(course => (
              <option key={course._id} value={course._id}>{course.course_name}</option>
            ))}
          </select>
        </div>
        <div className="form-group mb-0">
          <label>Subject Name:</label>
          <input type="text" onChange={handleSubjectNameChange} required  defaultValue={selectedSubject?.subject_name}/>
        </div>
        
        {/* <button type="submit">Add Subject</button> */}

        <div>
        {
              formType === 'Add' ? <button type="submit" className="add-form-btn">
                          <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
              </svg>
            </span>
            {formType} 
             Now
          </button>:
            <button onClick={EdithandleSubmit} className="add-form-btn">
              {/* <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg> &nbsp;
            </span> */}
            {formType}  Now
          </button>
           }
          <button type="submit" class="cancel-form-btn" onClick={cancelBtnHandler}>
            <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>
            </span>
            Cancel
          </button>
        </div>

      </form>
      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
    </div>
  );
}
