import {useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import './login.css'
import nexus_logo from '../../assets/logo.png';

function Login(props) {
    const [credentials, setCredentials] = useState({email:"", password:""})
    const [error_msg, setError] = useState("")

    useEffect(()=>{
        if(window.location.pathname !== '/') window.location.href="/"
    },[])
    
    const login_call=async ()=>{
        if(credentials.email.length === 0 || credentials.password.length === 0){
            setError("Please fill the credentials to login")
        }
        else{
            fetch(process.env.REACT_APP_API_URL+'/login',{
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(credentials)
            })
            .then(async(res)=>{
                const result = await res.json()
                console.log(result,"this is result")
                if(!result.success){                             
                    setError(result.message)                
                }
                else{ 
                    if(result?.result?.user?.is_registered) {
                        Cookies.set("nexus_qBank_login",true,{expires:9999});
                        Cookies.set("nexus_qBank_id",result.result.user._id,{expires:9999});                    
                        props.SwitchLoginStatus(true)
                       }
                    else {
                        setError("The Entered Email is not Registered")
                    }
                }

            })
            .catch((err)=>{
                console.log(err)
                setError("Somethings wrong!!! Please try again!")
                
            })
        }
    }

    const InputChange=(event)=>{
        event.preventDefault()
        if(event.keyCode === 13){
            login_call()
        }
        else{
            setCredentials({...credentials,[event.target.name]:event.target.value})
        }
    }
    return(
        <div className='login_page d-flex flex-column justify-content-center align-items-center'>
          <div className='login_container d-flex flex-column justify-cotent-center align-items-center shadow'>
            <img src={nexus_logo}  alt="NexusIqSolutions" height={50}/>
            <br/>
            <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="email" className="input_text">Email</label>
                <input type="email" name="email" placeholder="Enter Email" className="input_element" id="email" onKeyUp={InputChange}/>
            </div>
            <br/>
            <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="password" className="input_text">Password &nbsp;</label>
                <input type="password" name="password" placeholder="Enter Password" id="password" className="input_element" onKeyUp={InputChange}/>
            </div>
            <br/>
            <p id="error_msg">{error_msg}</p>
            <input type="button" className="login_button" value="Login" onClick={login_call}/>
            <a href="forgotPassword" className="mt-3">forgot password?</a>
          </div>
        </div>
    )
}
export default Login;