// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
    margin-bottom: 20px;
  }
  
  input {
    margin-right: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }`, "",{"version":3,"sources":["webpack://./src/components/Labels/labels.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["form {\n    margin-bottom: 20px;\n  }\n  \n  input {\n    margin-right: 10px;\n  }\n  \n  button {\n    padding: 5px 10px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #0056b3;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
