// Labels.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './labels.css'; // Import CSS file
import AddLabelForm from '../AddLabelForm/addLabelForm'; // Import the AddLabelForm component

export default function Labels() {
  const [labels, setLabels] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false); // State variable to manage form visibility

  useEffect(() => {
    fetchLabels();
  }, [page]); // Fetch labels whenever page changes

  const fetchLabels = async () => {
    try {
      const response = await axios.get(`http://localhost:8891/api/labels?page=${page}`);
      const { labels, totalPages } = response.data;
      setLabels(labels);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  const handleLabelAdded = () => {
    // Close the form and refresh the label list
    setShowForm(false);
    fetchLabels();
  };

  return (
    <div className="labels-container">
      <h2>Labels</h2>
      <button onClick={() => setShowForm(!showForm)}>Add Label</button>
      {showForm && <AddLabelForm onAddLabel={handleLabelAdded} />}
      <ul className="label-list">
        {labels.map((label, index) => (
          <li key={index} className="label-item">
            <span className="label-name">{label.label}</span>
            <span className="created-date">Created: {new Date(label.created.date).toLocaleDateString()}</span>
          </li>
        ))}
      </ul>
      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
