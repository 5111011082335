import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import Sidebar from '../../components/Sidebar/sidebar';
import './home.css'; // Import CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

export default function Home() {
  return (
    <div className="d-flex flex-column" style={{ height: '100vh' }}>
      <Header />
      <div className="flex-grow-1 d-flex main-left-sidebar">
        <div className="col-lg-2">
          <Sidebar />
        </div>
        <div className="col-lg-10">
          <div className="content app-main-content">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
      
    </div>
  );
}

