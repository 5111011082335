import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Context from "../../user_details";
import { playSound } from '../../helpers/otherSounds';

export default function UploadFileForm({ onUploadSuccess, cancelBtnHandler }) {
  const context = useContext(Context);
  const client_id = context.user.firm_id ?? "";
  const [selectedFile, setSelectedFile] = useState(null);
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [formData, setFormData] = useState({
    selectedCourse: '',
    selectedSubject: '',
    selectedTopic: '',
    questionType: 'multiple_choice',
    complexity: 'Easy',
    num_questions: 5
  });
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  // Fetch courses on component mount
  useEffect(() => {
    fetchCourses();
  }, [client_id]);

  const fetchCourses = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/courses/clientId/${client_id}`;
      if (context.user.user_type === "superAdmin") {
        url = `${process.env.REACT_APP_API_URL}/courses`;
      }

      const response = await axios.get(url);

      if (response.data && response.data.courses) {
        setCourses(response.data.courses);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Fetch subjects when course is selected
  useEffect(() => {
    if (formData.selectedCourse) {
      fetchSubjects(formData.selectedCourse);
    }
  }, [formData.selectedCourse]);

  // Fetch topics when subject is selected
  useEffect(() => {
    if (formData.selectedSubject) {
      fetchTopics(formData.selectedSubject);
    }
  }, [formData.selectedSubject]);

  const fetchSubjects = async (courseId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/search/${courseId}/${client_id}`);
      setSubjects(response.data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchTopics = async (subjectId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/topics/search/${subjectId}/${client_id}`);
      setTopics(response.data.result);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 10 * 1024 * 1024; // 10 MB

    if (file.size > maxFileSize) {
      setMessage('File size should not exceed 10 MB.');
      setMessageType('error');
      setSelectedFile(null);
      playSound('ERROR');
      return;
    }
    setSelectedFile(file);
  };

  const handleCourseChange = (e) => {
    const courseId = e.target.value;
    setFormData({
      ...formData,
      selectedCourse: courseId,
      selectedSubject: '',
      selectedTopic: '',
    });
    setSubjects([]);
    setTopics([]);
  };

  const handleSubjectChange = (e) => {
    const subjectId = e.target.value;
    setFormData({
      ...formData,
      selectedSubject: subjectId,
      selectedTopic: '',
    });
    setTopics([]);
  };

  const handleTopicChange = (e) => {
    setFormData({
      ...formData,
      selectedTopic: e.target.value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'num_questions' && value > 50) {
      setMessage('Maximum 50 questions allowed.');
      setMessageType('error');
      playSound('ERROR');
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setMessage('Please select a file.');
      setMessageType('error');
      return;
    }

    const fileData = new FormData();
    fileData.append('file', selectedFile);
    fileData.append('course_id', formData.selectedCourse);
    fileData.append('subject', formData.selectedSubject);
    fileData.append('topic', formData.selectedTopic);
    fileData.append('client_id', client_id);
    fileData.append('question_type', formData.questionType);
    fileData.append('complexity', formData.complexity);
    fileData.append('num_questions', formData.num_questions);
    fileData.append('created_by', context.user._id);  

    setIsUploading(true);
    setMessage('Question(s) generation using AI is in progress.. Please wait.');
    setMessageType('info');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/question/question-generator`, fileData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      playSound('SUCCESS');
      setMessage('Questions generation using AI is completed, please verify and move to question bank.');
      setMessageType('success');
      setTimeout(() => onUploadSuccess(response.data), 2000);
      
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file.');
      playSound('ERROR');
      setMessageType('error');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="upload-file-form general-add-form-container">
      <h3>Upload File</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Select Course:</label>
          <select
            className="form-select"
            value={formData.selectedCourse}
            onChange={handleCourseChange}
            required
            disabled={isUploading}
          >
            <option value="">Select Course</option>
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.course_name}
              </option>
            ))}
          </select>
        </div>

        {formData.selectedCourse && (
          <div>
            <label>Select Subject:</label>
            <select
              className="form-select"
              value={formData.selectedSubject}
              onChange={handleSubjectChange}
              required
              disabled={isUploading}
            >
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject._id} value={subject._id}>
                  {subject.subject_name}
                </option>
              ))}
            </select>
          </div>
        )}

        {formData.selectedSubject && (
          <div>
            <label>Select Topic:</label>
            <select
              className="form-select"
              value={formData.selectedTopic}
              onChange={handleTopicChange}
              required
              disabled={isUploading}
            >
              <option value="">Select Topic</option>
              {topics.map((topic) => (
                <option key={topic._id} value={topic._id}>
                  {topic.topic_name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label>Question Type:</label>
          <select
            className="form-select"
            name="questionType"
            value={formData.questionType}
            onChange={handleInputChange}
            required
            disabled={isUploading}
          >
            <option value="multiple_choice">Multiple Choice</option>
            <option value="true_false">True or False</option>
          </select>
        </div>

        <div>
          <label>Complexity:</label>
          <select
            className="form-select"
            name="complexity"
            value={formData.complexity}
            onChange={handleInputChange}
            required
            disabled={isUploading}
          >
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
          </select>
        </div>

        <div>
          <label>Number of Questions:</label>
          <input
            type="number"
            name="num_questions"
            value={formData.num_questions}
            onChange={handleInputChange}
            min="1"
            required
            disabled={isUploading}
          />
        </div>

        <div>
          <label>Upload File (PDF, DOCX, DOC):</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
            required
            disabled={isUploading}
          />
        </div>

        <button type="submit" className="upload-btn add-form-btn" disabled={isUploading}>
          {isUploading ? 'Uploading...' : 'Upload File'}
        </button>
        <button className="cancel-form-btn" onClick={cancelBtnHandler} disabled={isUploading}>
          Cancel
        </button>
      </form>

      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
    </div>
  );
}
