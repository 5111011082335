// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    /* background-color: #020d36; */
    background-color: #00155a;
    color: #fff;
    padding: 7px;
    text-align: center;
    /* position: fixed;
    z-index: 9;
    width: 83.5%;
    bottom: 0; */
  }

  footer h4.footer-content{
    color: #e3e9ec;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: normal;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB;;;gBAGY;EACd;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":["footer {\n    /* background-color: #020d36; */\n    background-color: #00155a;\n    color: #fff;\n    padding: 7px;\n    text-align: center;\n    /* position: fixed;\n    z-index: 9;\n    width: 83.5%;\n    bottom: 0; */\n  }\n\n  footer h4.footer-content{\n    color: #e3e9ec;\n    font-size: 13px;\n    margin-bottom: 0;\n    font-weight: normal;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
