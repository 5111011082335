// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-screen-wrapper {
    height: 80vh;
    /*This part is important for centering*/
    display: grid;
    place-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
  }
  
  .typing-demo {
    /* color: #00B3F4; */
    color: #0e71a1;
    font-weight: 500;
    width: 24ch;
    animation: typing 3s steps(24);
    white-space: nowrap;
    overflow: hidden;
    font-size: 2.75rem;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Default/default.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uCAAuC;IACvC,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,qBAAqB;EACvB;;EAEA;IACE,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE;MACE;IACF;EACF","sourcesContent":[".default-screen-wrapper {\n    height: 80vh;\n    /*This part is important for centering*/\n    display: grid;\n    place-items: center;\n    align-items: center;\n    justify-content: center;\n    align-content: center;\n    justify-items: center;\n  }\n  \n  .typing-demo {\n    /* color: #00B3F4; */\n    color: #0e71a1;\n    font-weight: 500;\n    width: 24ch;\n    animation: typing 3s steps(24);\n    white-space: nowrap;\n    overflow: hidden;\n    font-size: 2.75rem;\n  }\n  \n  @keyframes typing {\n    from {\n      width: 0\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
