import {useEffect, useState} from 'react';
import axios from 'axios';
import { AES, enc } from 'crypto-js';
import {useNavigate} from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
export default function ForgotPassword(){
    const [email, setEmail] = useState("");
    const [isemailVerified, setEmailVerification] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [otpInserted, setOTPInserted] =  useState("");
    const [generatedOTP, setOTPGenerated] = useState("");
    const [error, setError] = useState("");
    const [OTPError, setOTPError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate= useNavigate();
    const [show, setShow] = useState(false);
    const [user, setUser] = useState("");
    const [offCanvasBody, setOffCanvasBody] = useState("");
    useEffect(() => {
        if (generatedOTP.length > 0) {
            console.log("generatedotp", generatedOTP)
            sendOTP();
        }
    },[generatedOTP]);

    useEffect(() => {
        console.log(password, confirmPassword)
    }, [password, confirmPassword])

    const sendOTP = async() => {
        try{
            const result = await axios.post(`${process.env.REACT_APP_API_URL}/ums/mail-service/resetPassword/sendOTP`, {
                toEmail: email,
                token: AES.encrypt(generatedOTP,process.env.REACT_APP_UMS_SECRET_KEY).toString()
            })
            if(result.data.success) {
                setIsOTPSent(true);
                setUser(result.data._id);
            }
        }
        catch(e) {
            console.log(e);
        }
    }

    const otpGenerator = (digits=4) => {
        let n = digits, otp='';
        while (n>0) {
            let randomDigit = Math.floor(Math.random() * 10);
            if (randomDigit < 10 && randomDigit >=0) {
                otp=`${otp}${randomDigit}`;
                n--;
            }
            if (n==0) {
                setOTPGenerated(otp);
            }
        }
    }

    const verifyEmail = async() => {
       try {
        let result = await axios.post(`${process.env.REACT_APP_API_URL}/ums/verifyEmail`,{email});
        if (result.data.success) {
            console.log("user verifiedd")
            otpGenerator();
        }
        else {
            setError("This EmailId is not registered");
            const resetError = setTimeout(() => {
                setError("");
                clearInterval(resetError);
            }, 3000);
        }
       }
       catch(e) {
        console.log(e);
       }
    }

    const setNewPassword = (e) => {
        setPassword(e.target.value);
    }

    const setNewConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const updatePassword = async() => {
        if(isemailVerified) {
            if(password == confirmPassword && password.length >= 8 ) {
                setShow(true);
                try {
                    const result = await axios.patch(`${process.env.REACT_APP_API_URL}/ums/admin/password-update/${user}`,{
                        password
                    })
                    if(result.data.success) {
                        
                        setTimeout(() => {
                            navigate("/");
                            setShow(false);
                        }, 3000);
                    }
                    else {
                        setOffCanvasBody("Something went wrong")
                    }
                   
                }
                catch(e) {
                    console.log(e)
                }
            }
            else {
                setPasswordError("This Email is not registered");
                const resetError = setTimeout(() => {
                    setPasswordError("");
                    clearInterval(resetError);
                }, 3000)
            }
        }
        else {
            alert("email not verified")
        }
    }

    const verifyOTP = () => {
        if (otpInserted == generatedOTP) {
            setOTPInserted("");
            setPassword("");
            setConfirmPassword("");
            setEmailVerification(true);
        }
        else {
            setOTPError("Invalid OTP");
        }
    }

    if(isemailVerified) {
        return (
            <div className='d-flex flex-column justify-content-center'>
                <label>Set New Password <br/> <input type="password" value={password} onChange={setNewPassword}/></label>
                <label>Re-Enter Password <br/> <input type="password"   value={confirmPassword} onChange={setNewConfirmPassword}/></label>
                <div className='btn btn-primary' onClick={updatePassword}>Update Password</div>
                <p className='text-danger'>{passwordError}</p>
                <Offcanvas show={show} onHide={() => { setShow(false) }} placement='end' style={{height: 'fit-content'}}>
                    <Offcanvas.Body>
                        ✓ Your password has been reset successfully
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        )
    }

    else{
        if(isOTPSent) {
           return <div className='d-flex flex-column'>
                <label>
                    An OTP is sent to <b><i>{email}</i></b> Please Verify the OTP
                    <input type="text" onChange={(e) => {setOTPInserted(e.target.value)}}/>
                </label>
                <button className='btn btn-primary' onClick={verifyOTP}>Verify</button>
                <p className="text-danger">{OTPError}</p>
            </div>
        }
        else {
            return (
                <div>
                    <p>Enter the Email Id</p>
                    <input type="text" onChange={(e) => setEmail(e.target.value)}/>
                    <button className='btn btn-primary d-inline-flex' onClick={verifyEmail} disabled={isemailVerified}>Send OTP</button>
                    <p className='text-danger'>{error}</p>
                </div>)
        }
    }
}
